@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0; 
  font-family: "Wix Madefor Text", sans-serif;
  
  ::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#removeScrollbar::-webkit-scrollbar {
  display: none;
}

#removeScrollbarLeaderboard::-webkit-scrollbar {
  display: none;
}

.removeScrollbarClass::-webkit-scrollbar {
  display: none;
}

@media (min-height: 100px) {
  .scrollchat {
    min-height: 100px;
    max-height: 100px;
  }
}

@media (min-height: 700px) {
  .scrollchat {
    min-height: 150px;
    max-height: 150px;
  }
}

@media (min-height: 800px) {
  .scrollchat {
    min-height: 250px;
    max-height: 250px;
  }
}

@media (min-height: 200px) {
  .bigscrollchat {
    max-height: 400px;
  }
}

@media (min-height: 500px) {
  .bigscrollchat {
    max-height: calc(100vh - 25rem);
  }
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.fuck {
  background: linear-gradient(
    to right,
    #7953cd 20%,
    #00affa 30%,
    #0190cd 70%,
    #764ada 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

#game-phaser {
  z-index: 1 !important;
}

#graph {
  z-index: 1 !important;
}